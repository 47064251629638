<template>
  <div class="page-wrapper">
    <div :class="['header-wrap style1', { sticky: isSticky }]">
      <div class="header-top">
        <div class="container">
          <TopHeader />
        </div>
      </div>
      <MainNavbar />
    </div>

    <div class="content-wrapper">
      <PrivacyPolicy />
    </div>
    <MainFooter />
  </div>
</template>

<script>
import TopHeader from "../Layouts/TopHeader.vue";
import MainNavbar from "../Layouts/MainNavbar.vue";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy.vue";
import MainFooter from "../Layouts/MainFooter.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyPolicyPage",
  components: {
    TopHeader,
    //NavbarTwo,
    MainNavbar,
    //PageBanner,
    PrivacyPolicy,
    MainFooter,
  },
  data() {
    return {
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      if (scrollPos >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    });
  },
});
</script>