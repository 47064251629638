
import { defineComponent } from "vue";
//import PreLoader from "./components/Layouts/PreLoader.vue";
import BackToTop from "./components/Layouts/BackToTop.vue";
//import DemoSidebar from "./components/Layouts/DemoSidebar.vue";

export default defineComponent({
  name: "App",
  components: {
    //PreLoader,
    BackToTop,
    //DemoSidebar,
  },
/*   data() {
    return {
      isLoading: true,
      theme: {
        isDark: false,
      },
    };
  }, */
/*   mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  }, */
/*   methods: {
    toggleTheme() {
      this.theme.isDark = !this.theme.isDark;
      localStorage.setItem("theme", JSON.stringify(this.theme));
    },
  },
 *//*   created() {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      this.theme = JSON.parse(storedTheme);
    }
  }, */
  computed: {
    themeClass(): string {
      return "theme-light";
      //return this.theme.isDark ? "theme-dark" : "theme-light";
    },
  },
});
