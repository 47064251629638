
import TopHeader from "../Layouts/TopHeader.vue";
import MainNavbar from "../Layouts/MainNavbar.vue";
import MainBanner from "../HomeFour/MainBanner.vue";
//import AboutUs from "../HomeFour/AboutUs.vue";
//import BestServices from "../HomeFour/BestServices.vue";
import OurPartner from "../HomeFour/OurPartner.vue";
//import OurDoctors from "../HomeFour/OurDoctors.vue";
//import OurDepartment from "../HomeFour/OurDepartment.vue";
//import OurTestimonials from "../HomeFour/OurTestimonials.vue";
import PeopleAlsoAsk from "../HomeFour/PeopleAlsoAsk.vue";
//import GalleryExhibition from "../HomeFour/GalleryExhibition.vue";
import OurPricing from "../HomeFour/OurPricing.vue";
//import LatestNews from "../HomeFour/LatestNews.vue";
import KeepInTouch from "../HomeFour/KeepInTouch.vue";
//import TouchWithUs from "../HomeSix/TouchWithUs.vue";
import MainFooter from "../Layouts/MainFooter.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeFourPage",
  components: {
    TopHeader,
    MainNavbar,
    MainBanner,
    //AboutUs,
    //BestServices,
    OurPartner,
    //OurDoctors,
    //OurDepartment,
    //OurTestimonials,
    PeopleAlsoAsk,
    //GalleryExhibition,
    OurPricing,
    //LatestNews,
    KeepInTouch,
    //TouchWithUs,
    MainFooter,
  },
  data() {
    return {
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", () => {
      const scrollPos = window.scrollY;
      this.isSticky = scrollPos >= 100;
    });
  },
});
