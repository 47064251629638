<template>
  <div id="contact" class="contact-wrap style4 ptb-100 contact-padding">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
          <div class="section-title style1 text-center mb-40">
            <span>{{ $t('app.zustante_v_kontaktu') }}</span>
            <h2>{{ $t('app.kontaktni_formular') }}</h2>
          </div>
        </div>
      </div>

      <div class="row gx-5 align-items-center">
        <div class="col-lg-6">
          <!-- <div class="contact-bg-one bg-f"></div> -->
          <div class="contact-map">
            <iframe style="border:none" src="https://frame.mapy.cz/s/lufanutobe" width="700" height="466"
              frameborder="0"></iframe>
          </div>
        </div>

        <div class="col-lg-6">
          <form @submit.prevent="sendEmail" class="form-wrap" id="contactForm">
            <h5>{{ $t('app.kontaktujte_nas_dotaz') }}</h5>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group">
                  <input type="text" name="name" :placeholder="$t('app.kontaktni_formular_jmeno') + '*'" v-model="name" id="name" required />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <input type="email" name="email" id="email" v-model="email" required :placeholder="$t('app.kontaktni_formular_email') + '*'" />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <input type="text" name="phone_number" :placeholder="$t('app.kontaktni_formular_telefon') + '*'" id="phone_number" v-model="phone" required />
                </div>
              </div>

              <div class="col-lg-12">
                <div class="form-group">
                  <input type="text" name="msg_subject" :placeholder="$t('app.kontaktni_formular_predmet') + '*'" id="msg_subject" v-model="subject" required />
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group v1">
                  <textarea name="message" id="message" :placeholder="$t('app.kontaktni_formular_zprava')" v-model="message" cols="30"
                    rows="10" required></textarea>
                </div>
              </div>

              <div class="col-md-12">
                <button type="submit" class="btn style2">{{ $t('app.kontaktni_formular_odeslat') }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeepInTouch",
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: '',
    }
  },
  methods: {
    showInfo() {
      this.$swal(this.$t('app.kontaktni_formular_info_title'), this.$t('app.kontaktni_formular_info_text'), 'success');
    },
    showAlert() {
      this.$swal(this.$t('app.kontaktni_formular_error_title'), this.$t('app.kontaktni_formular_error_text'), 'error');
    },
    sendEmail() {
      const emailData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        subject: this.subject,
        message: this.message
      };

      //fetch('https://kojzardent.ttsoftware.cz/api/contactform', {
      fetch('/api/contactform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Nastavte správný Content-Type
        },
        body: JSON.stringify(emailData), // Převedení dat na JSON
      })
        .then(response => {
          if (response.ok) {
            this.name = "";
            this.email = "";
            this.phone = "";
            this.subject = "";
            this.message = "";

            this.showInfo();
          }
          else {
            console.error("chyba");
            this.showAlert();
          }
        })
        .catch(error => {
          // Zde zpracujte chybu, pokud se něco nepovede
          this.showAlert();
          console.error(error);
        });
    }
  }
};
</script>