<template>
  <div id="hero" class="hero-wrap bg-f hero-bg-14 style4" style="position: relative; z-index: 1;">
    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      <source src="../../assets/images/main/intro.mp4" type="video/mp4">
    </video>

    <div class="container" >
      <div class="row gx-5 align-items-end">
        <div class="col-lg-12">
          <div class="hero-content">
            <span class="banner-titulek" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200"  >
              {{ $t('banner.titulek') }}
            </span>
            <h1 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
              {{ $t('banner.motto') }}
            </h1>
            <h4 data-aos="fade-up" data-aos-duration="1200" data-aos-delay="400" >
              <span class="banner-h4" style="color: aliceblue;" v-html="$t('banner.text', { break: '<br />' })"></span>
            </h4>
            <div class="hero-btn" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500">
              <a href="#pricing" class="btn style2">{{ $t('banner.tlacitko') }}</a>
            </div>
          </div>
        </div>

        <!-- <div class="col-lg-3">
          <div class="hero-img-wrap">
            <img src="../../assets/images/hero/hero-img-15.png" alt="Image" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<!-- <script>
export default {
  name: "MainBanner",
  data() {
    return {
      contact: {}
    }
  },
  created() {
    fetch('/data/contact.json')
      .then(response => response.json())
      .then(data => {
        this.contact = data
      })
  }
};
</script> -->