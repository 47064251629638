<template>
  <div class="terms-wrap pt-100 pb-75">
    <div class="container">
      <div class="row gx-5">
        <div class="col-lg-12">
          <div class="single-terms">
            <h1>Prohlášení o zpracování osobních údajů</h1>
            <p>
              Prohlášení o zpracování osobních údajů dle nařízení Evropského parlamentu a Rady (EU) 2016/679 o ochraně
              fyzických osob v souvislosti se zpracováním osobních údajů a poučení subjektů údajů (dále jen „GDPR“)
            </p>
            <p>
              Správce osobních údajů KojzarDent s.r.o. se sídlem Kolová 250, Kolová, 360 01, Česká republika, IČ:
              17509165, Společnost zapsaná v obchodním rejstříku u Krajského
              soudu v Plzni, oddíl C, vložka 42686 (dále jen „správce“) Vás tímto informuje o zpracování Vašich osobních
              údajů a o Vašich právech.
            </p>
          </div>
          <div class="single-terms">
            <h5>Rozsah zpracování osobních údajů</h5>
            <p>
              Osobní údaje jsou zpracovány v rozsahu, v jakém je příslušný subjekt údajů správci poskytl, a to v
              souvislosti s uzavřením smluvního či jiného právního vztahu se správcem, nebo které správce shromáždil jinak
              a zpracovává je v souladu s platnými právními předpisy či k plnění zákonných povinností správce.
            </p>
          </div>
          <div class="single-terms">
            <h5>Zdroje osobních údajů</h5>
            <p>
            <ul class="content-feature-list list-style">
              <li>
                <i class="ri-checkbox-multiple-line"></i>přímo od subjektů údajů
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>veřejně přístupné rejstříky
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>seznamy a evidence (např. obchodní rejstřík, živnostenský
                rejstřík, katastr nemovitostí, veřejný telefonní seznam apod.)
              </li>
            </ul>
            </p>
          </div>
          <div class="single-terms">
            <h5>Kategorie osobních údajů, které jsou předmětem zpracování:</h5>
            <p>
            <ul class="content-feature-list list-style">
              <li>
                <i class="ri-checkbox-multiple-line"></i>adresní a identifikační údaje sloužící k jednoznačné a
                nezaměnitelné identifikaci subjektu údajů (např. jméno, příjmení, titul, adresa trvalého pobytu/sídla, IČ,
                DIČ) a údaje umožňující kontakt se subjektem údajů (kontaktní údaje – např. kontaktní adresa, číslo
                telefonu, číslo faxu, emailová adresa a jiné obdobné informace)
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>popisné údaje (např. bankovní spojení, datum)
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>další údaje nezbytné pro plnění smlouvy
              </li>
            </ul>
            </p>
          </div>
          <div class="single-terms">
            <h5>Kategorie příjemců osobních údajů</h5>
            <p>
            <ul class="content-feature-list list-style">
              <li>
                <i class="ri-checkbox-multiple-line"></i>zpracovatel
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>státní aj. orgány v rámci plnění zákonných povinností stanovených
                příslušnými právními předpisy
              </li>
            </ul>
            </p>
          </div>
          <div class="single-terms">
            <h5>Účel zpracování osobních údajů</h5>
            <p>
            <ul class="content-feature-list list-style">
              <li>
                <i class="ri-checkbox-multiple-line"></i>účely obsažené v rámci souhlasu subjektu údajů
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>jednání o smluvním vztahu
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>plnění smlouvy
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>ochrana práv správce, příjemce nebo jiných dotčených osob (např.
                vymáhání pohledávek správce)
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>archivnictví vedené na základě zákona
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>plnění zákonných povinností ze strany správce
              </li>
              <li>
                <i class="ri-checkbox-multiple-line"></i>ochrana životně důležitých zájmů subjektu údajů
              </li>
            </ul>
            </p>
          </div>
          <div class="single-terms">
            <h5>Způsob zpracování a ochrany osobních údajů</h5>
            <p>
              Zpracování osobních údajů provádí správce. Zpracování je prováděno v jeho provozovnách, pobočkách a sídle
              správce jednotlivými pověřenými zaměstnanci správce,
              příp. zpracovatelem. Ke zpracování dochází prostřednictvím výpočetní techniky, popř. i manuálním způsobem u
              osobních údajů v listinné podobě za dodržení všech
              bezpečnostních zásad pro správu a zpracování osobních údajů. Za tímto účelem přijal správce
              technicko-organizační opatření k zajištění ochrany osobních údajů,
              zejména opatření, aby nemohlo dojít k neoprávněnému nebo nahodilému přístupu k osobním údajům, jejich změně,
              zničení či ztrátě, neoprávněným přenosům, k jejich
              neoprávněnému zpracování, jakož i k jinému zneužití osobních údajů. Veškeré subjekty, kterým mohou být
              osobní údaje zpřístupněny, respektují právo subjektů údajů
              na ochranu soukromí a jsou povinny postupovat dle platných právních předpisů týkajících se ochrany osobních
              údajů.
            </p>
          </div>
          <div class="single-terms">
            <h5>Doba zpracování osobních údajů</h5>
            <p>
              V souladu se lhůtami uvedenými v příslušných smlouvách, ve spisovém a skartačním řádu správce či v
              příslušných právních předpisech jde o dobu nezbytně nutnou k zajištění práv a povinností plynoucích jak ze
              závazkového vztahu, tak i z příslušných právních předpisů.
            </p>
          </div>
          <div class="single-terms">
            <h5>Poučení</h5>
            <p>
              Správce zpracovává údaje se souhlasem subjektu údajů s výjimkou zákonem stanovených případů, kdy zpracování
              osobních údajů nevyžaduje souhlas subjektu údajů.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>