            <!-- class="logo-light" -->
<template>
  <div class="container">
    <div class="header-bottom">
      <nav class="navbar navbar-expand-lg navbar-light header-sticky">
        <router-link class="navbar-brand" to="/">
          <!-- <img class="logo-light" src="../../assets/images/main/logo_white6.png" alt="Image" /> -->
          <!-- <span>Zubní laboratoř  Kojzar Dent</span> -->
          <span class="title-font" v-html="$t('app.titulek', { space: '&nbsp;' })"></span> 
        </router-link>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="icon-bar top-bar"></span>
          <span class="icon-bar middle-bar"></span>
          <span class="icon-bar bottom-bar"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto">
            <li class="nav-item">
              <a class="nav-link" href="/#home" :class="{ active: activeId === 'home' }" @click="setActive('home')">
                {{ $t('app.uvod') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#faq" :class="{ active: activeId === 'faq' }" @click="setActive('faq')">
                {{ $t('app.otazky_odpovedi') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#pricing" :class="{ active: activeId === 'pricing' }"
                @click="setActive('pricing')">
                {{ $t('app.cenovy_plan') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#partners" :class="{ active: activeId === 'partners' }"
                @click="setActive('partners')">
                {{ $t('app.nasi_partneri') }}
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#contact" :class="{ active: activeId === 'contact' }"
                @click="setActive('contact')">
                {{ $t('app.kontakty') }}
              </a>
            </li>
          </ul>
        </div>

        <!--         <div class="other-option md-none">
          <div class="select-lang">
            <div
              class="navbar-option-item navbar-language dropdown language-option"
            >
              <button
                class="dropdown-toggle"
                @click="show = !show"
                :aria-pressed="show ? 'true' : 'false'"
                v-bind:class="{ show: button_show_state }"
                v-on:click="button_show_state = !button_show_state"
              >
                <img :src="image" alt="Image" />{{ name }}
              </button>

              <div
                class="dropdown-menu language-dropdown-menu"
                :class="{ show: show }"
              >
                <div v-if="newData.length > 0">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-for="data in newData"
                    :key="data.id"
                  >
                    <div v-on:click="toggleSelected(data), (show = !show)">
                      <img :src="data.image" alt="Image" />
                      {{ data.name }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="other-option md-none">
          <button
            class="searchbtn"
            @click="toggler = !toggler"
            :aria-pressed="toggler ? 'true' : 'false'"
            v-bind:class="{ toggler: button_toggler_state }"
            v-on:click="button_toggler_state = !button_toggler_state"
          >
            <i class="ri-search-2-line"></i>
          </button>
        </div> -->
        <!--         <div class="other-option md-none">
          <a
            href="#appointment"
            class="btn style1"
            :class="{ active: activeId === 'appointment' }"
            @click="setActive('appointment')"
          >
            Objednat
          </a>
        </div> -->
      </nav>
    </div>
  </div>
  <!--   <div class="search-area" :class="{ open: toggler }">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <button
            type="button"
            class="close-searchbox"
            @click="toggler = !toggler"
          >
            <i class="ri-close-line"></i>
          </button>
          <form action="#">
            <div class="form-group">
              <input type="search" placeholder="Search Here" autofocus="" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
export default {
  name: "MainNavbar",
  data() {
    return {
      activeId: null,
      toggler: false,
      button_toggler_state: false,

      show: false,
      active: false,
      button_show_state: false,
      /*       newData: [
              {
                id: "1",
                image: require("../../assets/images/cz.png"),
                name: "Česky",
              }
            ],
            name: "English",
            image: require("../../assets/images/cz.png"), */
      clicked: false,
    };
  },
  methods: {
    /*     toggleSelected(select, index) {
          if (this.clicked === index) {
            this.clicked = null;
          }
    
          this.clicked = index;
          this.name = select.name;
          this.image = select.image;
        }, */
    setActive(id) {
      this.activeId = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-toggler {
  border: none;
  padding: 7px;
  border-radius: 0;

  .icon-bar {
    width: 28px;
    transition: all 0.3s;
    background: var(--clr_redical);
    height: 2px;
    display: block;
  }

  .top-bar {
    transform: rotate(0);
    left: 0;
  }

  .middle-bar {
    opacity: 1;
    margin: 6px 0;
  }

  .bottom-bar {
    transform: rotate(0);
    left: 0;
  }

  &[aria-expanded="true"] {
    .top-bar {
      transform: rotate(45deg);
      transform-origin: 10% 10%;
      left: 4px;
      position: relative;
    }

    .middle-bar {
      opacity: 0;
      margin: 6px 0;
    }

    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 10% 90%;
      left: 4px;
      position: relative;
    }
  }
}

.select-lang {
  .navbar-language {
    .dropdown-toggle {
      position: relative;

      img {
        width: 20px;
      }

      &::before {
        right: -5px;
      }
    }
  }
}
</style>