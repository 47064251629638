<template>
  <section id="appointment" class="why-choose-wrap style1 ptb-100">
    <div class="container">
      <div class="row gx-5">
        <div style="display: grid; place-items: center;"
          class="col-lg-5"
          data-aos="fade-right"
          data-aos-duration="1200"
          data-aos-delay="200"
        >

        <!-- <video autoplay loop controls playsinline controlsList="nodownload" preload="auto" id="intro" style="width: 100%">
          <source src="../../assets/images/main/intro.mp4" type="video/mp4">
        </video> -->
        <img src="../../../src/assets/images/logo.jpg" alt="logo" >
        


<!--           <div class="book-appointment style1 bg-f">
            <div class="overlay bg-prussian op-95"></div>
            <h5>Book An Online Appointment</h5>
            <form action="#">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <input type="text" placeholder="Full Name" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input type="text" placeholder="Email Address" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input type="number" placeholder="Phone Number" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      placeholder="Booking Date"
                      onfocus="(this.type='date')"
                      onblur="(this.type='text')"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <select name="dept" id="dept">
                      <option data-display="Select Department" value="0">
                        Department
                      </option>
                      <option value="1">Dental Health</option>
                      <option value="2">Physical Health</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <select name="doc" id="doc">
                      <option data-display="Select Doctor" value="0">
                        Select Doctor
                      </option>
                      <option value="1">Dr. Josef Henry</option>
                      <option value="2">Dr. Kevin Martin</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <select name="location" id="location">
                      <option data-display="Select Location" value="0">
                        Select Location
                      </option>
                      <option value="1">South Arizona</option>
                      <option value="2">Florida</option>
                    </select>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <textarea
                      name="msg"
                      id="msg"
                      cols="30"
                      rows="10"
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <button type="submit" class="btn style2">
                    Book An Appointment
                  </button>
                </div>
              </div>
            </form>
          </div> -->
        </div>

        <div
          class="col-lg-7"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="200"
        >
          <div id="faq" class="faq-content ptb-100">
            <div class="content-title style1">
              <!-- <span>Časté otázky</span> -->
              <h2>
                {{ $t('otazky.caste_otazky') }}
              </h2>
            </div>
            <div class="accordion" id="accordionExample_one">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>
                      <i class="ri-add-line plus"></i>
                      <i class="ri-subtract-line minus"></i>
                    </span>
                    {{ $t('otazky.otazka_1') }}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample_one"
                >
                  <div class="accordion-body">
                    <div class="single-product-text">
                      <p>
                        {{ $t('otazky.odpoved_1') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>
                      <i class="ri-add-line plus"></i>
                      <i class="ri-subtract-line minus"></i>
                    </span>
                    {{ $t('otazky.otazka_2') }}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample_one"
                >
                  <div class="accordion-body">
                    <p>
                      {{ $t('otazky.odpoved_2') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span>
                      <i class="ri-add-line plus"></i>
                      <i class="ri-subtract-line minus"></i>
                    </span>
                    {{ $t('otazky.otazka_3') }}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingThree"
                  data-bs-parent="#accordionExample_one"
                >
                  <div class="accordion-body">
                    <p>
                      {{ $t('otazky.odpoved_3') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingFour">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <span>
                      <i class="ri-add-line plus"></i>
                      <i class="ri-subtract-line minus"></i>
                    </span>
                    {{ $t('otazky.otazka_4') }}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingFour"
                  data-bs-parent="#accordionExample_one"
                >
                  <div class="accordion-body">
                    <p>
                      {{ $t('otazky.odpoved_4') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PeopleAlsoAsk",
};
</script>