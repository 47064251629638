<template>
  <div class="row align-items-center">
    <div class="col-lg-8 col-md-7">
      <div class="header-top-left">
        <ul class="contact-info list-style">
          <li>
            <i class="flaticon-phone-call"></i>
            <a href="tel:420777267758">{{ $t('kontakt.telefon_hlavicka') }}</a>
          </li>
          <li>
            <i class="flaticon-time"></i>
            <p>{{ $t('kontakt.oteviraci_doba') }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-lg-4 col-md-5">
      <div class="header-top-right">
        <div class="select-lang">
          <div class="navbar-option-item navbar-language dropdown language-option">
            <button class="dropdown-toggle" @click="show = !show" :aria-pressed="show ? 'true' : 'false'"
              v-bind:class="{ show: button_show_state }" v-on:click="button_show_state = !button_show_state">
              <img :src="image" alt="Image" />
              {{ name }}
            </button>

            <div class="dropdown-menu language-dropdown-menu" :class="{ show: show }">
              <div v-if="newData.length > 0">
                <a class="dropdown-item" href="#" v-for="data in newData" :key="data.id">
                  <div v-on:click="toggleSelected(data), (show = !show)">
                    <img :src="data.image" alt="Image" />
                    {{ data.name }}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <ul class="social-profile list-style style1">
          <li>
            <a target="_blank" href="https://instagram.com/kojzardent/">
              <i class="ri-instagram-line"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  data: () => ({
    show: false,
    active: false,
    button_show_state: false,
    newData: [
      {
        id: "1",
        image: require("../../assets/images/cz.png"),
        name: "Česky",
        code: "cs"
      },
      {
        id: "2",
        image: require("../../assets/images/uk.png"),
        name: "English",
        code: "en"
      },
    ],
    name: "Česky",
    image: require("../../assets/images/cz.png"),
    clicked: false,
    contact: {}
  }),
  created() {
    const savedLanguage = JSON.parse(localStorage.getItem('selectedLanguage'));
    if (savedLanguage) {
      this.$i18n.locale = savedLanguage.code;
      this.name = savedLanguage.name;
      this.image = savedLanguage.image;
    }
  },
  methods: {
    toggleSelected(select, index) {
      if (this.clicked === index) {
        this.clicked = null;
      }   

      this.clicked = index;
      this.name = select.name;
      this.image = select.image;

      this.$i18n.locale = select.code; // aktualizuje locale v vue-i18n
      localStorage.setItem('selectedLanguage', JSON.stringify(select)); // uloží vybraný jazyk do localStorage      
    },
  }
}
</script>

<style lang="scss" scoped>
.header-top {
  padding: 13px 0;
  background-color: transparent;

  .header-top-left {
    .contact-info {
      li {
        display: inline-block;
        margin-right: 25px;
        padding-right: 25px;
        position: relative;

        &:after {
          position: absolute;
          top: 5px;
          right: 0;
          content: "";
          width: 1px;
          height: 16px;
          background-color: rgba(255, 255, 255, 0.4);
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }

        i {
          font-size: 14px;
          line-height: 0.8;
          color: var(--clr_redical);
          position: relative;
          top: 2px;
          margin-right: 6px;
        }

        a,
        p {
          color: var(--clr_emperor);
          font-size: 14px;
          display: inline-block;
          margin-bottom: 0;

          &:hover {
            color: var(--clr_prussian);
          }
        }
      }
    }
  }

  .header-top-right {
    display: flex;
    justify-content: flex-end;
    -webkit-box-pack: end;
    -ms-flex-pack: end;

    .social-profile {
      margin-left: 20px;
      padding-left: 20px;
      position: relative;

      &:after {
        position: absolute;
        top: 5px;
        left: 0;
        content: "";
        width: 1px;
        height: 18px;
        background-color: rgba(255, 255, 255, 0.4);
      }

      li {
        a {
          width: 30px;
          height: 30px;
          background-color: #eee;

          i {
            font-size: 16px;
            color: #999;
          }

          &:hover {
            background-color: var(--clr_redical);

            i {
              color: var(--clr_white);
            }
          }
        }
      }
    }
  }
}
</style>