<template>
  <div class="error-area">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="top-content">
            <ul>
              <li>4</li>
              <li>0</li>
              <li>4</li>
            </ul>
          </div>
          <h2>Chyba 404 : Stránka nebyla nalezena</h2>
          <p>
          </p>
          <router-link to="/" class="btn style1">
            Zpět na úvod
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorPage",
};
</script>

<style lang="scss" scoped>
.error-area {
  text-align: center;
  height: 100vh;

  .top-content {
    margin-bottom: 10px;

    ul {
      padding-left: 0;
      margin-bottom: 0;

      li {
        font-size: 200px;
        font-weight: 700;
        display: inline-block;
        line-height: 1;
        margin-right: 30px;
        color: var(--main-color);
        font-family: var(--heading-font-family);
        &:first-child {
          color: var(--heading-color);
        }
        &:last-child {
          color: var(--body-color);
        }
      }
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
  }
}

// Max Width 767px
@media only screen and (max-width: 767px) {
  .error-area {
    .top-content {
      ul {
        li {
          font-size: 100px;
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }

    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}

// Min Width 768px Max Width 991px
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .error-area {
    .top-content {
      ul {
        li {
          font-size: 120px;
          margin-right: 15px;
          margin-bottom: 15px;
        }
      }
    }

    h2 {
      font-size: 26px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 20px;
    }
  }
}
</style>