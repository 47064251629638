import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper" }
const _hoisted_2 = { class: "header-top" }
const _hoisted_3 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeader = _resolveComponent("TopHeader")!
  const _component_MainNavbar = _resolveComponent("MainNavbar")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_OurPricing = _resolveComponent("OurPricing")!
  const _component_PeopleAlsoAsk = _resolveComponent("PeopleAlsoAsk")!
  const _component_OurPartner = _resolveComponent("OurPartner")!
  const _component_KeepInTouch = _resolveComponent("KeepInTouch")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['header-wrap style4', { sticky: _ctx.isSticky }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TopHeader)
        ])
      ]),
      _createVNode(_component_MainNavbar)
    ], 2),
    _createVNode(_component_MainBanner),
    _createVNode(_component_OurPricing),
    _createVNode(_component_PeopleAlsoAsk),
    _createVNode(_component_OurPartner),
    _createVNode(_component_KeepInTouch),
    _createVNode(_component_MainFooter)
  ]))
}