import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import BootstrapVueNext from "bootstrap-vue-next";
import VueSweetAlert from 'vue-sweetalert2';
import { createI18n } from 'vue-i18n';

import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "vue3-carousel/dist/carousel.css";
import "./assets/custom.scss";
import 'sweetalert2/dist/sweetalert2.min.css'

const sweetOptions = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

async function loadLocaleMessages(locale: string) {
  const response = await fetch(`/locales/${locale}.json`);
  const messages = await response.json();
  return messages;
}

async function startApp() {
  // Načtěte výchozí jazykové zprávy
  const messages = {
    en: await loadLocaleMessages('en'),
    cs: await loadLocaleMessages('cs'),
  };

  const i18n = createI18n({
    locale: 'cs',
    fallbackLocale: 'cs',
    messages
  });

  const app = createApp(App).use(router);
  app.mixin({
    mounted() {
      AOS.init();
    },
  });
  app.use(BootstrapVueNext);
  app.use(VueSweetAlert, sweetOptions);
  app.use(i18n);
  app.mount("#app");
}

startApp();
