<template>
  <div id="partners" class="partner-wrap ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
          <div class="section-title style4 text-center mb-40">
            <h2>{{ $t('app.nasi_partneri') }}</h2>
          </div>
        </div>
      </div>

      <Carousel
        :autoplay="5000"
        :wrap-around="true"
        :breakpoints="breakpoints"
        pause-autoplay-on-hover
        class="partner-slider"
      >
        <Slide v-for="partner in partners" :key="partner.id">
          <div class="partner-item style2">
            <a :href="partner.url" target="_blank"><img :src="partner.image" alt="Image" /></a>            
          </div>
        </Slide>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Slide } from "vue3-carousel";

export default defineComponent({
  name: "OurPartner",
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    partners: [
      {
        id: 1,
        image: require("../../assets/images/partner/partner1.png"),
        url: "https://www.henryschein.cz"
      },
      {
        id: 2,
        image: require("../../assets/images/partner/partner2.png"),
        url: "https://www.completedental.cz"
      },
      {
        id: 3,
        image: require("../../assets/images/partner/partner3.png"),
        url: "https://www.dentamed.cz"
      },
      {
        id: 4,
        image: require("../../assets/images/partner/partner4.png"),
        url: "https://www.sadent.cz"
      },
      {
        id: 5,
        image: require("../../assets/images/partner/partner5.png"),
        url: "https://www.puradent.cz"
      },
      {
        id: 5,
        image: require("../../assets/images/partner/partner6.png"),
        url: "https://www.dident.cz"
      },
    ],
    breakpoints: {
      0: {
        itemsToShow: 2,
        snapAlign: "start",
      },
      768: {
        itemsToShow: 3,
        snapAlign: "center",
      },
       992: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      1200: {
        itemsToShow: 5,
        snapAlign: "center",
      }, 
    },
  }),
});
</script>