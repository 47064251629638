import { createWebHistory, createRouter } from "vue-router";

//import HomeOnePage from "../components/Pages/HomeOnePage.vue";
//import HomeTwoPage from "../components/Pages/HomeTwoPage.vue";
//import HomeThreePage from "../components/Pages/HomeThreePage.vue";
import HomeFourPage from "../components/Pages/HomeFourPage.vue";
//import HomeFivePage from "../components/Pages/HomeFivePage.vue";
//import HomeSixPage from "../components/Pages/HomeSixPage.vue";
//import HomeSevenPage from "../components/Pages/HomeSevenPage.vue";
//import HomeEightPage from "../components/Pages/HomeEightPage.vue";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage.vue";
/* import BlogDetailsPage from "../components/Pages/BlogDetailsPage.vue";
import ServiceDetailspage from "../components/Pages/ServiceDetailspage.vue";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage.vue";
import TermsOfServicePage from "../components/Pages/TermsOfServicePage.vue";*/
import ErrorPage from "../components/Pages/ErrorPage.vue";

const routes = [
  {
    path: "/",
    name: "HomeFourPage",
    component: HomeFourPage,
  },
  {
    path: "/gdpr",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
/*   {
    path: "/home-three",
    name: "HomeThreePage",
    component: HomeThreePage,
  }, */
/*   {
     path: "/home-four",
    name: "HomeFourPage",
    component: HomeFourPage,
  }, */
/*   {
    path: "/home-five",
    name: "HomeFivePage",
    component: HomeFivePage,
  }, */
/*   {
    path: "/home-six",
    name: "HomeSixPage",
    component: HomeSixPage,
  }, */
/*   {
    path: "/home-seven",
    name: "HomeSevenPage",
    component: HomeSevenPage,
  }, */
/*   {
    path: "/home-eight",
    name: "HomeEightPage",
    component: HomeEightPage,
  }, */
/*   {
    path: "/blog-details",
    name: "BlogDetailsPage",
    component: BlogDetailsPage,
  },
  {
    path: "/service-details",
    name: "ServiceDetailspage",
    component: ServiceDetailspage,
  },
  {
    path: "/gallery-details",
    name: "PortfolioDetailsPage",
    component: PortfolioDetailsPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfServicePage",
    component: TermsOfServicePage,
  }, */
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
