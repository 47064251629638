<template>
  <section id="pricing" class="pricing-wrap pb-75 ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
          <div class="section-title style1 text-center mb-40">
            <!-- <span>{{ $t('app.cenovy_plan') }}</span> -->
            <h2>{{ $t('app.cenovy_plan_text') }}</h2>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-xl-4 col-lg-6 col-md-6" v-for="pricing in pricings" :key="pricing.id">
          <div class="pricing-table style4">
            <div class="pricing-header-wrap">
              <div class="pricing-header">
                <h5>{{ pricing.title }}</h5>
                <h2>
                  {{ pricing.price }}<span>/ {{ pricing.period }}</span>
                </h2>
              </div>
              <span class="pricing-icon">
                <i :class="pricing.icon"></i>
              </span>
            </div>
            <div class="pricing-features">
              <!-- <h5>Our Best Features</h5> -->
              <ul class="list-style">
                <li v-for="feature in pricing.features" :key="feature.id" :class="feature.class">
                  <i class="ri-check-double-line"></i>
                  {{ feature.text }}
                </li>
              </ul>
            </div>
            <!--             <div class="purchase-pricing">
              <a href="#" class="btn style1">Choose This Plan</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "OurPricing",
  data() {
    return {
      pricings: []
    }
  },
  methods: {
    getPricings() {
      const currentLanguage = this.$i18n.locale; 
      fetch(`/data/pricing-${currentLanguage}.json`)
        .then(response => response.json())
        .then(data => {
          this.pricings = data
        })
    }
  },
  watch: {
    '$i18n.locale': {
      immediate: true,
      handler() {
        this.getPricings();
      }
    }
  }
});
</script>