<template>
  <footer class="footer-wrap">
    <div class="footer-top">
      <div class="container">
        <div class="row pt-100 pb-75">
          <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div class="footer-widget">
              <router-link to="/" class="footer-logo">
                <img src="../../assets/images/main/logo_white_footer.png" alt="Image" />
              </router-link>
              <p class="comp-desc">
                {{ $t('kontakt.registrace') }}
              </p>
            </div>
          </div>

          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div class="footer-widget">
              <h3 class="footer-widget-title">{{ $t('app.kontaktni_udaje') }}</h3>
              <ul class="contact-info list-style">
                <li>
                  <i class="flaticon-map"></i>
                  <p>{{ $t('kontakt.adresa') }}</p>
                </li>
                <li>
                  <i class="flaticon-map"></i>
                  <!-- <p>{{ $t('kontakt.provozovna') }}</p> -->
                  <p v-html="$t('kontakt.provozovna', { break: '<br />' })"></p>
                </li>
                <li>
                  <i class="flaticon-support"></i>
                  <!-- <a href="tel:+420 777 267 758">{{ $t('kontakt.telefon_paticka') }}</a> -->
                  <p v-html="$t('kontakt.telefon_paticka', { break: '<br />' })"></p>
                </li>
                <li>
                  <i class="flaticon-email"></i>
                  <a href="mailto:info@kojzardent.cz">info@kojzardent.cz</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 ps-xl-4">
            <div class="footer-widget">
              <h3 class="footer-widget-title">{{ $t('app.navigace') }}</h3>
              <ul class="footer-menu list-style">
                <li>
                  <a href="/#faq">
                    <i class="ri-arrow-right-s-line"></i>
                    {{ $t('app.otazky_odpovedi') }}
                  </a>
                </li>
                <li>
                  <a href="/#pricing">
                    <i class="ri-arrow-right-s-line"></i>
                    {{ $t('app.cenovy_plan') }}
                  </a>
                </li>
                <li>
                  <a href="/gdpr">
                    <i class="ri-arrow-right-s-line"></i>
                    {{ $t('app.gdpr') }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="footer-bottom">
          <div class="row align-items-center">
            <div class="col-md-6">
              <p class="copyright-text">
                <i class="ri-copyright-line"></i><span>{{ new Date().getFullYear() }}</span>, {{ $t('kontakt.spolecnost') }}
              </p>
            </div>
            <div class="col-md-6 text-md-end">
              <div class="social-link">
                <h6>{{ $t('app.sledujte_nas') }}:</h6>
                <ul class="social-profile list-style style1">
                  <li>
                    <a target="_blank" href="https://instagram.com/kojzardent/">
                      <i class="ri-instagram-line"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
